
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';

import DateDocumentFilter from '@/modules/document-filters/components/date-document-filter.vue';
import UserViewService, { UserViewServiceS } from '@/modules/user/user-view.service';
import RatesHintTooltip from '@/modules/rates/components/rates-hint-tooltip.vue';
import CurrencySwitcher from '@/modules/common/components/currency-switcher.vue';
import CurrencySwitcherTooltip from '@/modules/common/components/currency-switcher-tooltip.vue';
import type UserService from '@/modules/user/user.service';
import type HotelsService from '@/modules/hotels/hotels.service';
import type ClusterRatesService from '@/modules/cluster/cluster-rates.service';

import RatesScan from './rates-scan.vue';

@Component({
    components: {
        DateDocumentFilter,
        RatesScan,
        RatesHintTooltip,
        CurrencySwitcher,
        CurrencySwitcherTooltip,
    },
})
export default class RatesHeader extends Vue {
    @inject(KEY.UserService) private userService!: UserService;
    @inject(KEY.HotelsService) private hotelsService!: HotelsService;
    @inject(KEY.ClusterRatesService) clusterRatesService!: ClusterRatesService;
    @inject(UserViewServiceS) private userViewService!: UserViewService;

    infoTooltip: boolean = false;

    get isHotelUser() {
        return !this.userService.isChainOrClusterUser;
    }

    get hotelName(): string | null {
        const { currentHotelId = +this.$route.params.hotelId } = this.userService;

        if (!currentHotelId) {
            return null;
        }

        return this.hotelsService.hotelNames[currentHotelId];
    }

    toCluster() {
        this.userViewService.goToClusterPage();
    }
}
