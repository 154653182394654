import { render, staticRenderFns } from "./rates-graph-hotels.vue?vue&type=template&id=b881c12a&scoped=true&"
import script from "./rates-graph-hotels.vue?vue&type=script&lang=ts&"
export * from "./rates-graph-hotels.vue?vue&type=script&lang=ts&"
import style0 from "./rates-graph-hotels.vue?vue&type=style&index=0&id=b881c12a&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b881c12a",
  null
  
)

export default component.exports