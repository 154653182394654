var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('CustomTooltip',{staticClass:"rates-hint",scopedSlots:_vm._u([{key:"headline",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('rates.indicatorHint.title'))+" ")]},proxy:true},{key:"hoverContent",fn:function(){return [_c('i',{staticClass:"icon-Question-mark"})]},proxy:true}])},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('rates.indicatorHint.desc'))+" ")]),_c('div',{staticClass:"rates-hint__indicators-bar"},[_c('div',{staticClass:"good"},[_vm._v(_vm._s(_vm.$t('good')))]),_c('div',{staticClass:"fair"},[_vm._v(_vm._s(_vm.$t('fair')))]),_c('div',{staticClass:"poor"},[_vm._v(_vm._s(_vm.$t('poor')))])]),_c('div',{class:[
        'rates-hint__indicator',
        'rates-hint__indicator--no-data'
    ]},[_c('div',[_vm._v(_vm._s(_vm.$t('noData')))]),_vm._v(" "+_vm._s(_vm.$t('rates.indicatorHint.noDataMsg'))+" ")]),_c('div',{class:[
            'rates-hint__indicator',
            'rates-hint__indicator--sold-out',
        ]},[_c('div',[_vm._v(_vm._s(_vm.$t('soldOut')))]),_vm._v(" "+_vm._s(_vm.$t('rates.indicatorHint.soldOutMsg'))+" ")]),_c('div',{class:[
            'rates-hint__indicator',
            'rates-hint__indicator--sold-out',
        ]},[_c('div',[_vm._v(_vm._s(_vm.$tc('nog', 0, ['#'])))]),_vm._v(" "+_vm._s(_vm.$t('rates.indicatorHint.nog'))+" ")]),_c('div',{class:[
            'rates-hint__indicator',
            'rates-hint__indicator--na',
        ]},[_c('div',[_vm._v(_vm._s(_vm.$t('na')))]),_vm._v(" "+_vm._s(_vm.$t('rates.indicatorHint.naMsg'))+" ")]),_c('div',{class:[
            'rates-hint__indicator',
        ]},[_c('div',[_vm._v(_vm._s(_vm.$t('outOfRange')))]),_vm._v(" "+_vm._s(_vm.$t('rates.indicatorHint.outOfRangeMsg'))+" ")]),_c('div',{class:[
            'rates-hint__indicator',
        ]},[_c('div',[_vm._v(_vm._s(_vm.$t('titles.losRestriction')))]),_vm._v(" "+_vm._s(_vm.$t('rates.indicatorHint.losRestrictionMsg'))+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }