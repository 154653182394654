
import { Component, Vue, Prop } from 'vue-property-decorator';
import { inject } from '@/inversify';
import PRICE_SHOWN from '@/modules/rates/constants/price-shown.constant';
import { KEY } from '@/inversify.keys'; import type DocumentFiltersService from '../document-filters.service';

@Component
export default class TogglePrices extends Vue {
    @inject(KEY.DocumentFiltersService) private documentFiltersService!: DocumentFiltersService;

    @Prop({ type: Boolean, default: false })
    isDisabled!: boolean;

    @Prop({ type: Boolean, default: false })
    customModel!: boolean;

    @Prop({ type: String, default: null })
    value!: PRICE_SHOWN | null;

    get items() {
        return this.documentFiltersService.priceShownItems;
    }

    set model(newValue: PRICE_SHOWN) {
        if (this.value) {
            this.$emit('input', newValue);
            return;
        }

        this.setPriceShown(newValue);
    }

    get model(): PRICE_SHOWN {
        if (this.value) {
            return this.value;
        }

        return this.documentFiltersService.priceShown;
    }

    setPriceShown(price: PRICE_SHOWN) {
        this.documentFiltersService.priceShown = price;
    }
}
